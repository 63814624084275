import React, { useState, useEffect, Fragment, useRef } from "react";
import CheckIcon from "@material-ui/icons/CheckCircle";
import RemoveIcon from "@material-ui/icons/Delete";

import {
  IconButton,
  makeStyles,
  Button,
  Dialog,
  TextField,
  useMediaQuery,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { useField } from "react-final-form";
import { uiTypes } from "../../state";
import classNames from "classnames";
import {
  eventTicketTypes,
  formatMoney,
  nonEmptyArray,
  seatQtyOptions,
} from "../../lib";

export function SelectTickets({ option, onClose }) {
  const { optionId, label, type, rows = [], price } = option;
  const isAssign = type === eventTicketTypes.ASSIGNED;
  const classes = styles({ isOpen: !isAssign });
  const dispatch = useDispatch();
  const attendeeRef = useRef(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [selectedSeats, setSelectedSeats] = useState({});
  const [attendees, setAttendees] = useState(isAssign ? [] : [""]);
  const [openQty, setOpenQty] = useState(1);
  const [hasPrevSelected, setHasPrevSelected] = useState(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const ticketsField = useField("eventTickets").input;
  const ticketsValue = ticketsField.value;

  useEffect(() => {
    const _option = ticketsValue[optionId];
    if (!_option) return;
    const { selectedSeats: _selectedSeats, attendees: _attendees } = _option;
    setSelectedSeats(_selectedSeats);
    setAttendees(_attendees);
    setHasPrevSelected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSeat = (seatId, seatName) => {
    setSelectedSeats(prev => {
      return { ...prev, [seatId]: seatName };
    });
    setAttendees(prev => {
      return [...prev, ""];
    });
  };

  const removeSeat = seatId => {
    setSelectedSeats(prev => {
      const { [seatId]: _rm, ...rest } = prev;
      return rest;
    });
    setAttendees(prev => {
      return [...prev.slice(0, -1)];
    });
  };

  const onAttendeeChange = (key, value) => {
    setAttendees(prev => {
      const copy = [...prev];
      copy[key] = value;
      return copy;
    });
  };

  const onQtyChange = value => {
    const qty = Number(value);
    setOpenQty(qty);
    setAttendees(prev => {
      return Array.from({ length: qty }, (_, idx) => {
        return prev[idx] ? prev[idx] : "";
      });
    });
  };

  const onSave = () => {
    const missingAttendee = attendees.find(a => !a);
    if (missingAttendee !== undefined) {
      setSaveTriggered(true);
      if (attendeeRef.current) {
        attendeeRef.current.scrollIntoView({ behavior: "smooth" });
      }
      dispatch({
        type: uiTypes.UI_DATA_FETCH_ERROR,
        errorMessage: "Please fill in Attendee(s)",
      });
      return;
    }

    ticketsField.onChange({
      ...ticketsValue,
      [optionId]: {
        ...option,
        selectedSeats,
        attendees,
        qty: isAssign ? Object.keys(selectedSeats).length : openQty,
      },
    });
    onClose();
  };

  const onDelete = () => {
    const { [`${optionId}`]: _rm, ...rest } = ticketsValue;
    ticketsField.onChange(rest);
    onClose();
  };

  return (
    <Dialog open maxWidth={false} fullScreen={isMobile}>
      <div className={classes.container}>
        {/* TOP */}
        <div className={classes.top}>
          <div className={classes.header}>
            {isAssign ? "Select your seats" : "Reserve tickets"}
          </div>
          <div className={classes.close}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* MIDDLE */}
        <div className={classes.content}>
          <div className={classes.leftSide}>
            <div className={classes.priceLabel}>Price</div>
            <div className={classes.price}>{formatMoney(price)}</div>

            {!isAssign && (
              <Fragment>
                <div className={classes.qty}>Quantity</div>
                <TextField
                  select
                  size="small"
                  fullWidth
                  value={openQty}
                  onChange={({ target }) => onQtyChange(Number(target.value))}
                >
                  {seatQtyOptions.map(({ name, value }) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Fragment>
            )}

            {!isMobile && (
              <Attendees
                isAssign={isAssign}
                selectedSeats={selectedSeats}
                saveTriggered={saveTriggered}
                onAttendeeChange={onAttendeeChange}
                attendees={attendees}
              />
            )}
          </div>

          {isAssign && (
            <div className={classes.rightSide}>
              <div className={classes.sectionName}>{label}</div>
              <div className={classes.map}>
                <div className={classes.rowNames}>
                  {rows.map((row, i) => {
                    const { row_identifier } = row;
                    return (
                      <div key={i} className={classes.rowName}>
                        {row_identifier}
                      </div>
                    );
                  })}
                </div>
                <div>
                  {rows.map((row, i) => {
                    const { row_identifier, seats } = row;
                    return (
                      <div key={i} className={classes.row}>
                        {seats.map((seat, i2) => {
                          const {
                            seat_id,
                            seat_identifier,
                            is_blank,
                            taken,
                          } = seat;
                          const selected = Boolean(selectedSeats[seat_id]);
                          const seatName = `${row_identifier}${seat_identifier}`;
                          if (is_blank) {
                            return (
                              <div
                                key={i2}
                                className={classNames(
                                  classes.seat,
                                  is_blank && classes.blankSeat,
                                )}
                              />
                            );
                          }
                          return (
                            <div key={i2} className={classes.seatWrapper}>
                              <div
                                className={classNames(
                                  classes.seat,
                                  selected && classes.selectedSeat,
                                  taken && classes.takenSeat,
                                )}
                                onClick={() => {
                                  taken
                                    ? undefined
                                    : selected
                                    ? removeSeat(seat_id)
                                    : addSeat(seat_id, seatName);
                                }}
                              >
                                {seat_identifier}
                                {selected && (
                                  <CheckIcon className={classes.check} />
                                )}
                                {taken && <div className={classes.takenLine} />}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        {isMobile && (
          <div className={classes.mobileAttendees}>
            <Attendees
              isAssign={isAssign}
              selectedSeats={selectedSeats}
              saveTriggered={saveTriggered}
              onAttendeeChange={onAttendeeChange}
              attendees={attendees}
              attendeeRef={attendeeRef}
            />
          </div>
        )}
        {isMobile && <div className={classes.buttonSpacer} />}
        {/* BOTTOM */}
        <div className={classes.buttons}>
          <div>
            {hasPrevSelected && (
              <Button
                className={classes.delete}
                startIcon={<RemoveIcon />}
                onClick={onDelete}
              >
                Remove ticket(s)
              </Button>
            )}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancel}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.save}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function Attendees({
  isAssign,
  selectedSeats,
  attendees,
  saveTriggered,
  onAttendeeChange,
  attendeeRef,
}) {
  const classes = styles({ isOpen: !isAssign });
  if (isAssign && Object.keys(selectedSeats).length < 1) return <></>;
  return (
    <Fragment>
      <div className={classes.attendeeHeader} ref={attendeeRef}>
        Attendee information
      </div>

      <div className={classes.attendees}>
        {nonEmptyArray(attendees) &&
          attendees.map((attendee, index) => {
            const error = saveTriggered && !attendee;
            return (
              <div key={index} className={classes.attendeeInput}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Attendee name"
                  value={attendees[index] || ""}
                  onChange={({ target }) =>
                    onAttendeeChange(index, target.value)
                  }
                  error={error}
                  helperText={error ? "Required" : ""}
                />
              </div>
            );
          })}
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: ({ isOpen }) => (isOpen ? 600 : "unset"),
    maxWidth: "calc(100vw - 64px)",
    maxHeight: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: "24px 24px 16px 24px",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: ({ isOpen }) => (isOpen ? 600 : "unset"),
      maxWidth: "100%",
      overflow: "unset",
      padding: "24px 0 0 0",
      maxHeight: "unset",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "0 12px 0 16px",
    },
  },
  close: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      marginTop: -6,
    },
  },
  bottom: {
    marginTop: 24,
    height: 50,
    minHeight: 50,
    flexShrink: 0,
  },
  content: {
    display: "flex",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflow: "unset",
      flex: 1,
    },
  },
  leftSide: {
    width: ({ isOpen }) => (isOpen ? "100%" : 304),
    minWidth: ({ isOpen }) => (isOpen ? "100%" : 304),
    paddingRight: ({ isOpen }) => (isOpen ? 0 : 40),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      overflow: "unset",
      padding: () => "0 16px",
    },
  },
  rightSide: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      overflow: "unset",
      flex: 1,
    },
  },
  sectionName: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px 0 16px",
      marginBottom: 16,
    },
  },
  check: {
    color: theme.palette.secondary.main,
    fontSize: 10,
    position: "absolute",
    top: 2,
    left: 2,
  },
  buttonSpacer: {
    display: "flex",
    flex: 1,
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "flex-end",
    marginTop: 24,
    minHeight: 40,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      justifyContent: "end",
      position: "sticky",
      bottom: 0,
      zIndex: 9,
      marginBottom: 0,
      minHeight: "unset",
      marginTop: 0,
      padding: "16px 8px",
      flex: "0 0 auto",
      backgroundColor: "#FFFFFF",
    },
  },
  save: {
    width: 140,
    marginLeft: 16,
  },
  cancel: {
    width: 140,
  },
  priceLabel: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    paddingBottom: 8,
  },
  price: {
    fontSize: 32,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginBottom: 24,
  },
  qty: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    paddingBottom: 8,
  },
  attendeeHeader: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    marginTop: ({ isOpen }) => (isOpen ? 24 : 0),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: () => 24,
    },
  },
  attendeeInput: {
    marginBottom: 18,
  },
  mobileAttendees: {
    width: "100%",
    maxWidth: "100%",
    padding: "0 16px",
  },
  map: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    borderRadius: 8,
    border: "solid 1px #CACACA",
    backgroundColor: "#F3F3F3",
    padding: "24px 16px 16px 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      border: "none",
      padding: "16px 8px 16px 0",
      overflowY: "unset",
    },
  },
  rowNames: {
    paddingRight: 16,
    paddingLeft: 16,
    height: "fit-content",
    position: "sticky",
    left: 0,
    backgroundColor: "#F3F3F3",
    zIndex: 9,
  },
  rowName: {
    height: 40,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.25,
  },
  row: {
    display: "flex",
    minWidth: "fit-content",
  },
  attendees: {
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      overflow: "unset",
    },
  },
  seatWrapper: {
    position: "relative",
    width: "fit-content",
    height: "fit-content",
  },
  seat: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    height: 40,
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    marginRight: 8,
    marginBottom: 8,
    border: `1px solid #B0B0B0`,
    color: theme.palette.primary.main,
    overflow: "hidden",
    cursor: "pointer",
  },
  selectedSeat: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  blankSeat: {
    backgroundColor: "#CCCCCC",
    cursor: "unset",
    border: "none",
  },
  takenSeat: {
    opacity: 0.5,
    border: "1px solid #979797",
    cursor: "unset",
  },
  takenLine: {
    borderRadius: 8,
    position: "absolute",
    top: 0,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    height: 40,
    background: `linear-gradient(to bottom left, transparent calc(50% - 1px),  #979797 calc(50% - 1px), #979797 50%, transparent 50%)`,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
  },
  delete: {
    color: "#f00",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
}));
