import React, { useState, useEffect, Fragment } from "react";
import {
  makeStyles,
  Container,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InfoIcon from "@material-ui/icons/Info";
import OpenIcon from "@material-ui/icons/ShuffleRounded";
import ReservedIcon from "@material-ui/icons/EventSeatRounded";
import { useDispatch, useSelector } from "react-redux";
import { useField } from "react-final-form";
import {
  APIClient,
  EVENT_TICKET_EL_ID,
  eventTicketTypes,
  formatCurrencyWithSymbol,
  hexToRGBA,
  isPlainObjectWithProperties,
} from "../../lib";
import { uiTypes } from "../../state";
import { SelectTickets } from "./SelectTickets.jsx";
import classNames from "classnames";
import { SeatingMap } from "./SeatingMap.jsx";

export function EventTickets() {
  const { hasEvents, campaignId } = useSelector(state => state.campaign);
  const classes = styles();
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [showMap, setShowMap] = useState(false);
  const ticketsField = useField("eventTickets").input;
  const {
    ticketOptions = [],
    event_name,
    map_image,
    location_address,
    location_city,
    location_name,
    location_state,
    location_zip,
    date,
    totalSeats,
    totalSeatsSold,
    totalAssignedSeatSold,
    totalOpenSeatsSold,
  } = event;
  const eventDetails = { event_name, date };

  useEffect(() => {
    if (campaignId && hasEvents) {
      fetchEvents();
      ticketsField.onChange({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, hasEvents]);

  const fetchEvents = async () => {
    const url = `/campaign_events/webpage_data?campaign_id=${campaignId}`;
    const response = await APIClient.get(url);
    const { error, errorMessage, data } = response;
    if (error) {
      return dispatch({ type: uiTypes.UI_DATA_FETCH_ERROR, errorMessage });
    }
    if (Array.isArray(data) && data.length) {
      setEvent(data[0]);
    }
  };

  // add if not reg donation campaign return
  if (!hasEvents || !isPlainObjectWithProperties(event)) return <></>;
  return (
    <Fragment>
      <div className={classes.hashLink} id={EVENT_TICKET_EL_ID}></div>
      <div className={classes.wrapper}>
        <Container>
          <div className={classes.info}>
            <div className={classes.header}>{event_name}</div>
            <div className={classes.subheader}>Select a ticket option</div>
            <div className={classes.msg}>
              Ticket holders with reserved seats will have priority entry to the
              stadium before flex seats.
            </div>

            <div className={classes.box}>
              <div className={classes.boxTop}>
                <div
                  className={classNames(
                    classes.topSection,
                    classes.leftTopSection,
                  )}
                >
                  <TodayIcon className={classes.icon} />
                  <div className={classes.topSectionTxt}>{date}</div>
                </div>
                <div className={classes.topSection}>
                  <LocationOnIcon className={classes.icon} />
                  {!isMobile && (
                    <div className={classes.topSectionTxt}>
                      {location_name}, {location_address}, {location_city}{" "}
                      {location_state} {location_zip}
                    </div>
                  )}

                  {isMobile && (
                    <div className={classes.topSectionTxt}>
                      <div>{location_name}</div>
                      <div>{location_address}</div>
                      <div>
                        {location_city} {location_state}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.boxMiddle}>
                <div className={classes.sold}>
                  {totalSeatsSold}/{totalSeats} tickets sold
                </div>
                <div className={classes.reserved}>
                  {totalAssignedSeatSold} Reserved seats sold
                </div>
                <div className={classes.flex}>
                  {totalOpenSeatsSold} Flex seats sold
                </div>
              </div>

              {map_image && (
                <div className={classes.boxBottom}>
                  <span
                    className={classes.viewMap}
                    onClick={() => setShowMap(true)}
                  >
                    View seating map
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={classes.cardContainer}>
            {ticketOptions.map((option, index) => {
              return (
                <Card key={index} option={option} eventDetails={eventDetails} />
              );
            })}
          </div>
        </Container>
      </div>

      {showMap && map_image && (
        <SeatingMap map_image={map_image} onClose={() => setShowMap(false)} />
      )}
    </Fragment>
  );
}

function Card({ option, eventDetails }) {
  const classes = styles();
  const ticketsField = useField("eventTickets").input;
  const ticketsValue = ticketsField.value;
  const [showDialog, setShowDialog] = useState(false);
  const [tooltip, setTooltip] = useState("");
  const { optionId, label, type, price, seatCount, soldSeats } = option;
  const selected = Boolean(ticketsValue[optionId]);
  const { qty = 0, selectedSeats } = ticketsValue[optionId] || {};
  const isOpenVenue = type === eventTicketTypes.VENUE_OPEN;
  const isOpenSection = type === eventTicketTypes.SECTION_OPEN;
  const isAssign = type === eventTicketTypes.ASSIGNED;

  useEffect(() => {
    let _tooltip = "";
    if (type === eventTicketTypes.VENUE_OPEN) {
      _tooltip =
        "Enjoy the freedom to sit anywhere in the stadium that is not reserved. Seating is first-come, first-serve across all sections.";
    } else if (type === eventTicketTypes.SECTION_OPEN) {
      _tooltip =
        "Choose any seat within your designated section that is not reserved. Seating is first-come, first-serve.";
    } else
      _tooltip =
        "Select and reserve your exact seat during booking for a guaranteed spot.";

    setTooltip(_tooltip);
  }, [type]);

  return (
    <div className={classNames(classes.card, selected && classes.selectedCard)}>
      <div>
        <div className={classes.soldChip}>
          {soldSeats}/{seatCount} sold
        </div>
        <div className={classes.cardName}>{label}</div>

        <div className={classes.tagWrapper}>
          <div
            className={classNames(
              classes.tag,
              isAssign && classes.assignBGColor,
            )}
          >
            {!isAssign && <OpenIcon className={classes.typeIcon} />}
            {isAssign && (
              <ReservedIcon
                className={classNames(classes.typeIcon, classes.assignColor)}
              />
            )}
            <div
              className={classNames(
                classes.tagText,
                isAssign && classes.assignColor,
              )}
            >
              {isOpenVenue
                ? "Flex seat"
                : isOpenSection
                ? "Section flex"
                : "Reserved seat"}
            </div>

            <Tooltip
              title={tooltip}
              disableFocusListener
              classes={{
                tooltip: classes.tooltipContainer,
                arrow: classes.tooltipArrow,
                tooltipPlacementBottom: classes.tooltip,
              }}
            >
              <InfoIcon
                className={classNames(
                  classes.tagIcon,
                  isAssign && classes.assignColor,
                )}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.price}>{formatCurrencyWithSymbol(price)}</div>
        {selected && (
          <div className={classes.added}>
            {generateAddedTxt(qty, selectedSeats)}
          </div>
        )}
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => setShowDialog(true)}
          className={selected ? classes.selectedButton : ""}
        >
          {selected ? "Manage tickets" : "Select"}
        </Button>
      </div>
      {showDialog && (
        <SelectTickets
          option={{ ...option, ...eventDetails }}
          onClose={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}

function generateAddedTxt(qty, selectedSeats) {
  let txt = `${qty} added`;
  if (isPlainObjectWithProperties(selectedSeats)) {
    const seatsTxt = Object.values(selectedSeats).join(", ");
    txt = `${txt} ${seatsTxt}`;
  }
  return txt;
}

const styles = makeStyles(theme => ({
  hashLink: {
    height: 1,
    visibility: "hidden",
    position: "absolute",
    marginTop: -80,
    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },
  wrapper: {
    padding: "40px 0",
  },
  info: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  header: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    textAlign: "center",
    paddingBottom: 16,
  },
  subheader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    textAlign: "center",
    paddingBottom: 16,
  },
  msg: {
    fontSize: 16,
    lineHeight: 1.25,
    letterSpacing: 0.2,
    textAlign: "center",
    paddingBottom: 24,
  },
  box: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
    padding: 16,
    marginBottom: 24,
    justifyContent: "center",
  },
  boxTop: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 24,
    },
  },
  topSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  leftTopSection: {
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: 1,
      marginBottom: 16,
    },
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -1,
    },
  },
  topSectionTxt: {
    fontSize: 16,
    letterSpacing: 0.2,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      // marginLeft: 0,
      lineHeight: 1.5,
    },
  },
  boxMiddle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sold: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    color: theme.palette.primary.main,
  },
  reserved: {
    fontSize: 13,
    letterSpacing: 0.15,
    color: "#466e50",
    padding: "3px 12px",
    borderRadius: 16,
    backgroundColor: "#effcf0",
    width: "fit-content",
    marginLeft: 16,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      margin: "11px 0 8px 0",
    },
  },
  flex: {
    fontSize: 13,
    letterSpacing: 0.15,
    color: "#40687f",
    padding: "3px 12px",
    borderRadius: 16,
    backgroundColor: "#e5f5ff",
    width: "fit-content",
  },
  boxBottom: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginBottom: 8,
    },
  },
  viewMap: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0 0 0 -24px",
    width: "calc(100% + 24px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      padding: "0 8px",
    },
  },
  card: {
    width: 266,
    marginLeft: 24,
    marginBottom: 24,
    borderRadius: 8,
    border: "solid 1px #979797",
    padding: "8px 24px 24px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: 162,
      marginLeft: 8,
      marginBottom: 16,
      marginRight: 8,
      padding: "6px 12px 12px 12px",
    },
  },
  selectedCard: {
    border: `3px solid ${theme.palette.secondary.main}`,
    backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
  },
  soldChip: {
    padding: "6px 8px",
    borderRadius: 14,
    backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.25),
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginLeft: -16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -5,
    },
  },
  cardName: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: 16,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
    },
  },
  price: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
    },
  },
  addedActions: {
    width: "100%",
    height: 36,
    borderRadius: 19,
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  addedActionIcons: {
    color: theme.palette.secondary.contrastText,
  },
  addedNum: {
    color: theme.palette.secondary.contrastText,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
  },
  tagWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  tag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px 10px",
    borderRadius: 16,
    backgroundColor: "#e5f5ff",
    width: "fit-content",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 4px",
    },
  },
  typeIcon: {
    fontSize: 18,
    marginRight: 6,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      marginRight: 4,
      fontSize: 17,
    },
  },
  tagIcon: {
    fontSize: 18,
    marginLeft: 6,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 4,
      fontSize: 17,
    },
  },
  tagText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#40687f",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  assignColor: {
    color: "#466e50",
  },
  assignBGColor: {
    backgroundColor: "#effcf0",
  },
  tooltipArrow: {
    display: "none",
  },
  tooltip: {
    marginTop: "4px !important",
  },
  tooltipContainer: {
    backgroundColor: "#B0B0B0",
    width: 180,
    color: "#FFFFFF",
    fontSize: 12,
    lineHeight: 1.25,
    letterSpacing: 0.15,

    borderRadius: 4,
    padding: 6,
  },
  selectedButton: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  bottom: {
    minHeight: 96,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 26,
    [theme.breakpoints.down("sm")]: {
      marginTop: 18,
      minHeight: 86,
    },
  },
  added: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.29,
    letterSpacing: 0.18,
    textAlign: "center",
    color: theme.palette.secondary.main,
    marginBottom: 6,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    letterSpacing: 0.15,
    marginBottom: 4,
  },
}));
